<template>
  <div class="justify-content-center d-flex">
    <div class="mt-5 d-flex flex-column flex-wrap">
      <form>
        <div v-if="passwordSet" class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Current Password</span>
          </div>
          <input
            type="password"
            class="form-control"
            aria-label="Current Password"
            aria-describedby="basic-addon1"
            autocomplete="current-password"
            v-model="currentPassword"
          />
        </div>

        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">New Email</span>
          </div>
          <input
            type="email"
            class="form-control"
            aria-label="New Email"
            aria-describedby="basic-addon1"
            v-model="newEmail"
          />
        </div>

        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">New Email Confirm</span>
          </div>
          <input
            type="email"
            class="form-control input-fix"
            aria-label="New Email confirm"
            aria-describedby="basic-addon1"
            v-model="newEmailConfirm"
          />
          <div class="input-group">
            <button
              class="btn btn-outline-secondary mx-auto mt-3"
              type="button"
              @click="saveEmail"
            >
              Change Email
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
const api = require("../api");
import Swal from "sweetalert2";

export default {
  components: {},
  data() {
    return {
      currentPassword: "",
      newEmail: "",
      newEmailConfirm: "",
      passwordSet:true,
    };
  },
  methods: {
    isEmailValid(email) {
      let re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    saveEmail() {
      let oldEmail = this.$store.getters["auth/user"].email;

      if (this.currentPassword.length == 0) {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Current Password field cannot be empty!",
        });
        return;
      }

      if (!this.isEmailValid(this.newEmail)) {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "New email must be a valid email address!",
        });
        return;
      }

      if (this.newEmail == oldEmail) {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "New email must be different from old email!",
        });
        return;
      }

      if (this.newEmail !== this.newEmailConfirm) {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Email fields must match!",
        });
        return;
      }

      let changes = [
        {
          key: "email",
          value: this.newEmail,
          oldValue: oldEmail,
        },
      ];

      Swal.showLoading();
      api
        .updateResource(
          this.$store.getters["auth/user"].id,
          "user",
          "guardgg",
          changes,
          {
            token: this.$route.query.token,
            currentPassword, // actually sending the password to be checked.
          }
        )
        .then((res) => {
          Swal.hideLoading();

          if (res.status == 200 && res.data.successful.length > 0) {
            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Email change successful!",
            });
            
            this.$router.push("/account");
          } else if (res.status == 200 && res.data.successful.length == 0) {
            console.log("here")
            if (res.data.errors.map(e => e.message).includes("Existing email")) {
              Swal.fire({
                title: "Error",
                icon: "error",
                text: "Email is already taken. Please try another.",
              });
            }
            else if (res.data.errors.includes("Password mismatch")){
              Swal.fire({
                title: "Error",
                icon: "error",
                text: "Invalid current password entered.",
              });
            }
          } else {
            Swal.fire({
              title: "Error",
              icon: "error",
              text: "Something went wrong! Please try again.",
            });
          }
        })
        .catch((err) => {
          console.error(err);

          Swal.hideLoading();
        });
    },
  },
  mounted() {
    // This was commented out for some reason? why?
    let token = this.$route.query.token;
    if (!this.$route.query.token) {
      this.$router.push("/"); // Redirect to homepage if attempting to visit without a JWT token
    }

    let info = atob(token.split('.')[1]);
    let parsedInfo = JSON.parse(info);
    this.passwordSet = parsedInfo.passwordSet
  },
  watch: {},
};
</script>

<style lang="scss">
.input-fix {
  border-radius: 0 !important;
}
</style>
